<template>
   <div class="privacy-register">
      <router-link to="/privacy-policy">Privacy Policy</router-link>
      and
      <router-link to="/terms-of-service">Terms of Service</router-link>
      <p class="copyright">Copyright © 2025 D8-CREW</p>
   </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.privacy-register a
   text-decoration: underline
.copyright
   font-size: 12px
   color: rgba(0, 0, 0, 0.5)
   margin-top: 10px
   margin-bottom: 0
   text-align: center
</style>
